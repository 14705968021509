import React from 'react';
import styled from '@emotion/styled';

const DivContainer = styled.div`
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 75px auto;

    @media only screen and (max-width: 997px){
        width: 525px;
        max-height: unset;
        min-height: 100vh;
        margin: 120px auto 0 auto;
        display:block;
    }
    @media only screen and (max-width: 624px){
        margin: 65px auto 15px auto;
    }
    @media only screen and (max-width: 567px){
        width: 95%;
    }
`

const Container = ({children}) => {
    return (
        <DivContainer>
            {children}
        </DivContainer>
    )
}

export default Container
