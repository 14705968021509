import React from 'react'
import styled from '@emotion/styled';
import Modal from 'react-bootstrap/Modal';

const ModalCustomize = styled(Modal)`
    z-index: 1050;

    & .modal-dialog{
        max-width: 100vw;
        width: 80vw;
        height: 100vh;
        margin: 0 auto;
    }
    & .modal-content{
        top:0vw;
        border: unset;
        border-radius:unset;
    }
    & .modal-body{
        height: 80vh;
        overflow:hidden;
        max-height: unset;
    }
    @media only screen and (max-width: 997px){
        & .modal-dialog{
            max-width: 90vw;
            width: 90vw; 
        }
        & .modal-body{
            height: 75vh;
            overflow:hidden;
            max-height: unset;
        }
    }
`

const MapModal = ({DisableModal,isOpen,children}) => {
    return (
        <ModalCustomize backdrop={true} centered={true} show={isOpen} onHide={DisableModal}>
            <Modal.Body>
                {children}
            </Modal.Body>
        </ModalCustomize>
    )
}

export default MapModal
