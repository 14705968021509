import React from 'react'
import styled from '@emotion/styled';
import Modal from 'react-bootstrap/Modal';

const ModalCustomize = styled(Modal)`
    z-index: 1050;

    & .modal-content{
        top:0vh;
        border: unset;
        border-radius:unset;
    }

    & .modal-dialog{
        max-width: unset;
        width: 950px;
        height: 100vh;
        margin: 0 auto;
    }
    & .modal-body{
        height: auto;
        max-height: unset;
    }
    @media only screen and (max-width: 1700px){
        & .modal-dialog{
            width: 820px;
            height: auto;
        }
        & .modal-content{
            margin: 30px auto;
        }
    }
    @media only screen and (max-width: 1500px){
        & .modal-dialog{
            width: 750px;
        }
    }
    @media only screen and (max-width: 1350px){
        & .modal-dialog{
            width: 680px;
        }
    }
    @media only screen and (max-width: 997px){
        & .modal-dialog{
            width: 500px;
        }
    }
    @media only screen and (max-width: 620px){
        & .modal-dialog{
            width: 85%;
        }
    }
`

const CreditsModal = ({isOpen,SetIsOpen,option,children,DisableModal}) => {
    return (
        <ModalCustomize backdrop={true} centered={true} show={isOpen} onHide={DisableModal}>
            <Modal.Body>
                {children}
            </Modal.Body>
        </ModalCustomize>
    )
}

export default CreditsModal
