import React from 'react'
import styled from '@emotion/styled';
import Modal from 'react-bootstrap/Modal';

const ModalCustomize = styled(Modal)`
    z-index: 100;
    padding-left: 0px !important;

    & .modal-content{
        top:0vh;
        border: unset;
        border-radius:unset;
    }

    & .modal-dialog{
        max-width: 100vw;
        width:100vw;
        margin: 0 auto;
    }
    & .modal-body{
        min-height: 100vh;
    }

`

const ModalFluid = ({isOpen,SetIsOpen,option,children}) => {
    const DisableModal = _ => {
        if(option===0){
            SetIsOpen( values => ({...values,Modal1IsOpen:false}) )
        }else if(option===1){
            SetIsOpen( values => ({...values,Modal2IsOpen:false}) )
        }else if(option===2){
            SetIsOpen( values => ({...values,Modal3IsOpen:false}) )
        }else if(option===3){
            SetIsOpen( values => ({...values,Modal4IsOpen:false}) )
        }else if(option===4){
            SetIsOpen( values => ({...values,Modal5IsOpen:false}) )
        }else if(option===5){
            SetIsOpen( values => ({...values,Modal6IsOpen:false}) )
        }else if(option===10){
            SetIsOpen(false)
        }
    } 

    return (
        <ModalCustomize backdrop={false} show={isOpen} onHide={DisableModal}>
            <Modal.Body>
                {children}
            </Modal.Body>
        </ModalCustomize>
    )
}

export default ModalFluid
