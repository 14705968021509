import React, { useEffect, useState } from 'react';
import Layout from '../share/layout';
import Background from './Background/Background';
import Container from './Container/Container';
import PrincipalButton from './PrincipalButton/PrincipalButton';
import ModalFluid from './ModalFluid/ModalFluid';
import Title from './Title/Title';
import CreditsButton from './CreditsButton/CreditsButton';
import PrincipalModalChildren from './PrincipalModalChildren';
import CreditsModalChildren from './CreditsModalChildren';
import ShareButton from '../share/ShareButton'
import { useLocation } from "@reach/router"

const Template5 = ({ pageContext }) => {
  let location = useLocation();
  const [DisplayModal, setDisplayModal] = useState({
    Modal1IsOpen: false,
    Modal2IsOpen: false,
    Modal3IsOpen: false,
    Modal4IsOpen: false,
    Modal5IsOpen: false,
    Modal6IsOpen: false
  })
  const [BackgroundPart1, setBackgroundPart1] = useState()

  const [DisplayCreditsModal, setDisplayCreditsModal] = useState(false)

  const QueryResultData = pageContext.datacarrusel;

  const { titulo, ShareImage, metaTitle, metaKeywords, metaDescripcin, BackgroundsImages, Section1ImagesButtonsA, Section1ImagesButtonsB, Section1title, Section1CreditsButton, Section2Titles, Section2PrincipalImage, Section2ImageButtonPlayA, Section2ImageButtonPlayB, Section2ImageReturnButtonA, Section2ImageReturnButtonB, Section2ImageMapButtonA, Section2ImageMapButtonB, Section2Text1, Section2Text2, Section2Text3, Section2Text4, Section2Text5, Section2Text6, Section2ListMaps, Section2ListVideos, Section3title, Section3PrincipalImagesA, Section3PrincipalImagesB, Section3ImageReturnButton, Section3Text, Section2ModalTitle, Section3ModalTitle, Section3TitleImagesModal, Section3CreditsModalText1, Section3CreditsModalText2, Section3PrincipalImagesModal } = QueryResultData

  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 991) {
      setBackgroundPart1(BackgroundsImages[0].fluid)
    } else {
      setBackgroundPart1(BackgroundsImages[3].fluid)
    }
    return () => {

    }
  }, [size.width, BackgroundsImages])
  return (
    <Layout
      descritpion={metaDescripcin}
      imgurl={ShareImage.file.url}
      subtitile={metaDescripcin}
      title={titulo}
      metaTitle={metaTitle}
      metaKeywords={metaKeywords}
      metaDescripcin={metaDescripcin}
    >
      <h1 className="d-none">{metaDescripcin}</h1>
      <h2 className="d-none">{titulo}</h2>
      <div id="template5">
        <ShareButton url={location.pathname} />
        <Background ImgFile={BackgroundPart1}>
          <Container>
            {Section1title &&
              <Title ImgFile={Section1title} />
            }
            {(Section1CreditsButton && Section1CreditsButton[0] && Section1CreditsButton[1]) &&
              <CreditsButton
                ImgFile={Section1CreditsButton[0]}
                SecondImgFile={Section1CreditsButton[1]}
                DisplayCreditsModal={setDisplayCreditsModal}
              />
            }
            <div className="w-100 d-flex flex-wrap">
              {Section1ImagesButtonsA && Section1ImagesButtonsA.map((ButtonImage, i) => (
                <PrincipalButton
                  key={ButtonImage.id}
                  ImgFile={ButtonImage}
                  SecondImgFile={Section1ImagesButtonsB[i]}
                  SetIsOpen={setDisplayModal}
                  option={i}
                />
              ))}
            </div>
          </Container>
        </Background>
        {Section2Titles && Section2Titles.map((Title, i) => (
          <ModalFluid
            key={Title.id}
            isOpen={
              i === 0 ? (DisplayModal.Modal1IsOpen) :
                i === 1 ? (DisplayModal.Modal2IsOpen) :
                  i === 2 ? (DisplayModal.Modal3IsOpen) :
                    i === 3 ? (DisplayModal.Modal4IsOpen) :
                      i === 4 ? (DisplayModal.Modal5IsOpen) :
                        i === 5 && (DisplayModal.Modal6IsOpen)
            }
            SetIsOpen={setDisplayModal}
            option={i}
            data-backdrop="false"
          >
            <Background ImgFile={BackgroundsImages[1].fluid}>
              <PrincipalModalChildren
                Title={Title}
                SetIsOpen={setDisplayModal}
                option={i}
                PrincipalImage={(Section2PrincipalImage && Section2PrincipalImage[i]) ? Section2PrincipalImage[i] : null}
                ButtonPlayA={(Section2ImageButtonPlayA && Section2ImageButtonPlayA[i]) ? Section2ImageButtonPlayA[i] : null}
                ButtonPlayB={(Section2ImageButtonPlayB && Section2ImageButtonPlayB[i]) ? Section2ImageButtonPlayB[i] : null}
                ButtonMAPA={(Section2ImageMapButtonA && Section2ImageMapButtonA[i]) ? Section2ImageMapButtonA[i] : null}
                ButtonMAPB={(Section2ImageMapButtonB && Section2ImageMapButtonB[i]) ? Section2ImageMapButtonB[i] : null}
                MapList={(Section2ListMaps && Section2ListMaps[i]) ? Section2ListMaps[i] : null}
                ListVideo={(Section2ListVideos && Section2ListVideos[i]) ? Section2ListVideos[i] : null}
                BackButtonA={(Section2ImageReturnButtonA && Section2ImageReturnButtonA[i]) ? Section2ImageReturnButtonA[i] : null}
                BackButtonB={Section2ImageReturnButtonB[i]}
                Json={i === 0 ? Section2Text1 :
                  i === 1 ? Section2Text2 :
                    i === 2 ? Section2Text3 :
                      i === 3 ? Section2Text4 :
                        i === 4 ? Section2Text5 :
                          i === 5 && Section2Text6
                }
                ModalTitle={(Section2ModalTitle && Section2ModalTitle[i]) ? Section2ModalTitle[i] : null}
              />
            </Background>
          </ModalFluid>
        ))}

        <ModalFluid
          isOpen={DisplayCreditsModal}
          option={10}
          data-backdrop="false"
        >
          <Background ImgFile={BackgroundsImages[2].fluid}>
            <CreditsModalChildren
              Title={Section3title}
              ImgFile={Section3PrincipalImagesA}
              SecondImgFile={Section3PrincipalImagesB}
              Section3ImageReturnButton={Section3ImageReturnButton}
              Section3Text={Section3Text}
              setDisplayCreditsModal={setDisplayCreditsModal}
              ModalTitle={Section3ModalTitle}
              CreditsModalTitle={Section3TitleImagesModal}
              Text1={Section3CreditsModalText1}
              Text2={Section3CreditsModalText2}
              CreditsImagesModal={Section3PrincipalImagesModal}
            />
          </Background>
        </ModalFluid>
      </div>
    </Layout>
  )
}

export default Template5

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}