import React from 'react'
import styled from '@emotion/styled';
import Image from 'gatsby-image';

const TitleContainer = styled.div`
    width:50%;
    max-height:20%;
    margin-bottom: 2%;
    @media only screen and (max-width: 997px){
        width: 100%;
    }
`

const Title = ({ ImgFile }) => {
	return (
		<TitleContainer>
			<Image
				fluid={ImgFile.fluid}
				alt={ImgFile.description ? ImgFile.description : ImgFile.title}
			/>
		</TitleContainer>
	)
}

export default Title
