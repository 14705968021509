import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

const Button = styled.button`
    width: 20.5%;
    background: unset;
    outline: none !important;
    border: 0;
    margin: 0 auto;
    position: absolute;
    right: -2%;
    top: 7%;
    &:hover .PrincipalButtonImage{
        opacity:0
    }
    &:hover .PrincipalButtonImageAbsolute{
        opacity:1;
    }
    @media only screen and (max-width: 997px){
        position: relative;
        width: 40%;
        margin-left: auto;
        margin-right: 0;
        display: block;
    }
    @media only screen and (max-width: 530px){
        width: 215px;
    }
`
const AbsoluteImage = styled(Image)`
    width: 100%;
    position: absolute !important;
    z-index: 50;
    top: 0;
    left: 0;
    opacity:0;
    transition: opacity 0.5s;

`
const RelativeImage = styled(Image)`
    opacity:1;
    transition: opacity 0.5s;
`

const CreditsButton = ({ ImgFile, SecondImgFile, DisplayCreditsModal }) => {
	return (
		<Button onClick={() => DisplayCreditsModal(true)}>
			<RelativeImage
				fluid={ImgFile.fluid}
				className="PrincipalButtonImage"
				alt={ImgFile.description ? ImgFile.description : ImgFile.title}
			/>
			<AbsoluteImage
				fluid={SecondImgFile.fluid}
				className="PrincipalButtonImageAbsolute"
				alt={SecondImgFile.description ? SecondImgFile.description : SecondImgFile.title}
			/>
		</Button>
	)
}

export default CreditsButton
