import React from 'react'
import styled from '@emotion/styled';
import Modal from 'react-bootstrap/Modal';

const ModalCustomize = styled(Modal)`
z-index: 1050;

& .modal-dialog{
    width: 1200px;
    margin: 0 auto;
}
& .modal-content{
    top:0vw;
    border: unset;
    border-radius:unset;
}
& .modal-body{
    overflow:hidden;
    max-height: unset;
    height: auto;
    background: black;
}

@media only screen and (max-width: 1500px){
    & .modal-dialog{
        width: 845px;
    }
}

@media only screen and (max-width: 997px){
    & .modal-dialog{
        max-width: 95vw;
        width: 95vw; 
    }
    & .modal-body{
        overflow:hidden;
        max-height: unset;
    }
}
`

const VideoModal = ({isOpen,SetIsOpen,option,children,DisableModal}) => {
    return (
        <ModalCustomize backdrop={true} centered={true} show={isOpen} onHide={DisableModal}>
            <Modal.Body>
                {children}
            </Modal.Body>
        </ModalCustomize>
    )
}

export default VideoModal
