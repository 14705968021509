import React, { useState } from 'react'
import BackButton from './BackButton/BackButton'
import template5 from './template5.module.css'
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import CreditsModal from './CreditsModal/CreditsModal';

const CreditsContainerImages = styled.button`
    width: 27.5%;
    background: unset;
    outline: none !important;
    border: 0;
    margin: 0 auto;
    position:relative;
    &:hover .PrincipalButtonImage{
        opacity:0
    }
    &:hover .PrincipalButtonImageAbsolute{
        opacity:1;
    }
    @media only screen and (max-width: 997px){
        width:50%
    }

`
const AbsoluteImage = styled(Image)`
    width: 100%;
    position: absolute !important;
    z-index: 50;
    top: 0;
    left: 0;
    opacity:0;
    transition: opacity 0.5s;

`
const RelativeImage = styled(Image)`
    opacity:1;
    transition: opacity 0.5s;
`
const CreditsTitle = styled(Image)`
    width: 32%;
    margin:0 auto;

`

const CreditsModalChildren = ({ Title, ImgFile, SecondImgFile, Section3ImageReturnButton, Section3Text, setDisplayCreditsModal, ModalTitle, CreditsModalTitle, Text1
  , Text2, CreditsImagesModal }) => {
  const DisplayModal = () => {
    setDisplayCreditsModal(false)
  }
  const [AuthorModals, setAuthorModals] = useState({
    FirstModal: false,
    SecondModal: false
  })

  return (
    <div className={`${template5.Modal_Element} ${template5.CreditsModal_Element}`}>
      <BackButton
        BackButtonA={Section3ImageReturnButton[0]}
        BackButtonB={Section3ImageReturnButton[1]}
        DisplayModal={DisplayModal}
      />
      <CreditsTitle
        className={template5.title_responsive}
        fluid={Title.fluid}
        alt={Title.description ? Title.description : Title.title}
      />
      <div className={template5.credits_container_images_responsive}>
        <CreditsContainerImages>
          <div onKeyDown={(ev) => { if (ev.keyCode === 13) { setAuthorModals({ ...AuthorModals, FirstModal: true }) } }} onClick={() => setAuthorModals({ ...AuthorModals, FirstModal: true })} role="button" tabIndex="0">
            <RelativeImage
              fluid={ImgFile[0].fluid}
              className="PrincipalButtonImage"
              alt={ImgFile[0].description ? ImgFile[0].description : ImgFile[0].title}
            />
            <AbsoluteImage
              fluid={SecondImgFile[0].fluid}
              className="PrincipalButtonImageAbsolute"
              alt={SecondImgFile[0].description ? SecondImgFile[0].description : SecondImgFile[0].title}
            />
          </div>

        </CreditsContainerImages>
        <CreditsContainerImages>
          <div onKeyDown={(ev) => { if (ev.keyCode === 13) { setAuthorModals({ ...AuthorModals, SecondModal: true }) } }} onClick={() => setAuthorModals({ ...AuthorModals, SecondModal: true })} role="button" tabIndex="0">
            <RelativeImage
              fluid={ImgFile[1].fluid}
              className="PrincipalButtonImage"
              alt={ImgFile[1].description ? ImgFile[1].description : ImgFile[1].title}
            />
            <AbsoluteImage
              fluid={SecondImgFile[1].fluid}
              className="PrincipalButtonImageAbsolute"
              alt={SecondImgFile[1].description ? SecondImgFile[1].description : SecondImgFile[1].title}
            />
          </div>
        </CreditsContainerImages>
      </div>
      <CreditsContainerImages className={template5.credits_container_images}>
        <div onKeyDown={(ev) => { if (ev.keyCode === 13) { setAuthorModals({ ...AuthorModals, FirstModal: true }) } }} onClick={() => setAuthorModals({ ...AuthorModals, FirstModal: true })} role="button" tabIndex="0">
          <RelativeImage
            fluid={ImgFile[0].fluid}
            className="PrincipalButtonImage"
            alt={ImgFile[0].description ? ImgFile[0].description : ImgFile[0].title}
          />
          <AbsoluteImage
            fluid={SecondImgFile[0].fluid}
            className="PrincipalButtonImageAbsolute"
            alt={SecondImgFile[0].description ? SecondImgFile[0].description : SecondImgFile[0].title}
          />
        </div>
      </CreditsContainerImages>
      <div className={template5.CreditsContainerInfo}>
        <CreditsTitle
          className={template5.title_no_responsive}
          fluid={Title.fluid}
          alt={Title.description ? Title.description : Title.title}
        />
        <div className={template5.TextContainerCredits}>
          {documentToReactComponents(Section3Text.json)}
        </div>
      </div>
      <CreditsContainerImages className={template5.credits_container_images}>
        <div onClick={() => setAuthorModals({ ...AuthorModals, SecondModal: true })} onKeyDown={(ev) => { if (ev.keyCode === 13) { setAuthorModals({ ...AuthorModals, SecondModal: true }) } }} role="button" tabIndex="0">
          <RelativeImage
            fluid={ImgFile[1].fluid}
            className="PrincipalButtonImage"
            alt={ImgFile[1].description ? ImgFile[1].description : ImgFile[1].title}
          />
          <AbsoluteImage
            fluid={SecondImgFile[1].fluid}
            className="PrincipalButtonImageAbsolute"
            alt={SecondImgFile[1].description ? SecondImgFile[1].description : SecondImgFile[1].title}
          />
        </div>
      </CreditsContainerImages>
      <CreditsModal
        DisableModal={() => { setAuthorModals({ ...AuthorModals, FirstModal: false }) }}
        isOpen={AuthorModals.FirstModal}
      >
        <div style={{ 'cursor': 'pointer' }} onKeyDown={(ev) => { if (ev.keyCode === 13) { setAuthorModals({ ...AuthorModals, FirstModal: false }) } }} onClick={() => setAuthorModals({ ...AuthorModals, FirstModal: false })} role="button" tabIndex="0">
          <Image fluid={ModalTitle.fluid} />
        </div>
        <div className={template5.CreditModalBody}>
          <div className={template5.CreditsModalContainer}>
            <div className={template5.CreditsModalSubcontainer}>
              <Image fluid={CreditsImagesModal[0].fluid} />
            </div>
            <div className={template5.CreditsModalSubcontainer}>
              <Image fluid={CreditsModalTitle[0].fluid} />
              <div className={template5.CreditModalText}>
                {documentToReactComponents(Text1.json)}
              </div>
            </div>
          </div>
        </div>
      </CreditsModal>
      <CreditsModal
        DisableModal={() => { setAuthorModals({ ...AuthorModals, SecondModal: false }) }}
        isOpen={AuthorModals.SecondModal}
      >
        <div style={{ 'cursor': 'pointer' }} onKeyDown={(ev) => { if (ev.keyCode === 13) { setAuthorModals({ ...AuthorModals, SecondModal: false }) } }} onClick={() => setAuthorModals({ ...AuthorModals, SecondModal: false })} role="button" tabIndex="0">
          <Image fluid={ModalTitle.fluid} />
        </div>
        <div className={template5.CreditModalBody}>
          <div className={template5.CreditsModalContainer}>
            <div className={template5.CreditsModalSubcontainer}>
              <Image fluid={CreditsImagesModal[1].fluid} />
            </div>
            <div className={template5.CreditsModalSubcontainer}>
              <Image fluid={CreditsModalTitle[1].fluid} />
              <div className={template5.CreditModalText}>
                {documentToReactComponents(Text2.json)}
              </div>
            </div>
          </div>
        </div>
      </CreditsModal>
    </div>
  )
}

export default CreditsModalChildren
