import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

const Button = styled.button`
    width: 16.5%;
    background: unset;
    outline: none !important;
    border:0;
    margin: 0 auto;
    position: relative;
    &:hover .PrincipalButtonImage{
        opacity:0
    }
    &:hover .PrincipalButtonImageAbsolute{
        opacity:1;
    }
    @media only screen and (max-width: 997px){
        width: 26.5%;
    }
    @media only screen and (max-width: 557px){
        width: 40%;
    }
`
const AbsoluteImage = styled(Image)`
    width: 100%;
    position: absolute !important;
    z-index: 50;
    top: 0;
    left: 0;
    opacity:0;
    transition: opacity 0.5s;

`
const RelativeImage = styled(Image)`
    opacity:1;
    transition: opacity 0.5s;
`


const PrincipalButton = ({ImgFile,SecondImgFile,option,SetIsOpen}) => {

    const ShpwModal = _ => {
        if(option===0){
            SetIsOpen( values => ({...values,Modal1IsOpen:true}) )
        }else if(option===1){
            SetIsOpen( values => ({...values,Modal2IsOpen:true}) )
        }else if(option===2){
            SetIsOpen( values => ({...values,Modal3IsOpen:true}) )
        }else if(option===3){
            SetIsOpen( values => ({...values,Modal4IsOpen:true}) )
        }else if(option===4){
            SetIsOpen( values => ({...values,Modal5IsOpen:true}) )
        }else if(option===5){
            SetIsOpen( values => ({...values,Modal6IsOpen:true}) )
        }
    } 

    return (
        <Button onClick={ShpwModal}>
            <RelativeImage 
                fluid={ImgFile.fluid} 
                className="PrincipalButtonImage"
                alt={ImgFile.description ? ImgFile.description : ImgFile.title}
            />
            <AbsoluteImage 
                fluid={SecondImgFile.fluid} 
                className="PrincipalButtonImageAbsolute"
                alt={SecondImgFile.description ? SecondImgFile.description : SecondImgFile.title}
            />
        </Button>
    )
}

export default PrincipalButton
