import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const BackgroundContainer = styled(BackgroundImage)`
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 997px){
        height: unset;
    }

`

const Background = ({ImgFile, children}) => {
    return (
        <BackgroundContainer fluid={ImgFile}>
            {children}
        </BackgroundContainer>
    )
}

export default Background
