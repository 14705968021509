import React, { useState } from 'react'
import template5 from './template5.module.css'
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BackButton from './BackButton/BackButton';
import VideoModal from './VideoModal/VideoModal';
import MapModal from './MapModal/MapModal';

const Button = styled.button`
    width: 27.5%;
    background: unset;
    outline: none !important;
    border: 0;
    margin: 0 auto;
    left: 0;
    position: absolute;
    right: 0;
    bottom: -14%;
    &:hover .PrincipalButtonImage{
        opacity:0
    }
    &:hover .PrincipalButtonImageAbsolute{
        opacity:1;
    }
`

const MapButton = styled.button`
    width: 45%;
    background: unset;
    outline: none !important;
    border: 0;
    left: 0;
    position: absolute;
    bottom: -30%;
    &:hover .PrincipalButtonImage{
        opacity:0
    }
    &:hover .PrincipalButtonImageAbsolute{
        opacity:1;
    }
    @media only screen and (max-width: 997px){
        width: 220px !important;
        margin-top: 35px !important;
        position: relative !important;
    }
`

const AbsoluteImage = styled(Image)`
    width: 100%;
    position: absolute !important;
    z-index: 50;
    top: 0;
    left: 0;
    opacity:0;
    transition: opacity 0.5s;

`
const RelativeImage = styled(Image)`
    opacity:1;
    transition: opacity 0.5s;
`

const PrincipalModalChildren = ({ ModalTitle, Title, PrincipalImage, ButtonPlayA, ButtonPlayB, Json, ButtonMAPA, ButtonMAPB, BackButtonA, BackButtonB, option, SetIsOpen, MapList
  , ListVideo }) => {
  const [DisplayMapVideoModal, setDisplayMapVideoModal] = useState({
    VideoModal: false,
    MapModal: false
  })
  const DisplayModal = () => {
    if (option === 0) {
      SetIsOpen(values => ({ ...values, Modal1IsOpen: false }))
    } else if (option === 1) {
      SetIsOpen(values => ({ ...values, Modal2IsOpen: false }))
    } else if (option === 2) {
      SetIsOpen(values => ({ ...values, Modal3IsOpen: false }))
    } else if (option === 3) {
      SetIsOpen(values => ({ ...values, Modal4IsOpen: false }))
    } else if (option === 4) {
      SetIsOpen(values => ({ ...values, Modal5IsOpen: false }))
    } else if (option === 5) {
      SetIsOpen(values => ({ ...values, Modal6IsOpen: false }))
    }
  }


  return (
    <div className={template5.Modal_Element}>
      <BackButton
        DisplayModal={DisplayModal}
        BackButtonA={BackButtonA}
        BackButtonB={BackButtonB}
      />
      <div className={template5.PrincipalButtonContainer}>
        {PrincipalImage &&
          <Image fluid={PrincipalImage.fluid} />
        }
        {(ListVideo && ListVideo !== '-') &&
          <Button
            onKeyDown={(ev) => { if (ev.keyCode === 13){setDisplayMapVideoModal({ ...DisplayMapVideoModal, VideoModal: true })}}}
            onClick={() => { setDisplayMapVideoModal({ ...DisplayMapVideoModal, VideoModal: true }) }}
          >
            {ButtonPlayA &&
              <RelativeImage
                fluid={ButtonPlayA.fluid}
                className="PrincipalButtonImage"
                alt={ButtonPlayA.description ? ButtonPlayA.description : ButtonPlayA.title}
              />
            }
            {ButtonPlayB &&
              <AbsoluteImage
                fluid={ButtonPlayB.fluid}
                className="PrincipalButtonImageAbsolute"
                alt={ButtonPlayB.description ? ButtonPlayB.description : ButtonPlayB.title}
              />
            }
          </Button>
        }
      </div>
      <div className={template5.InfoContainer}>
        <Image fluid={Title.fluid} />
        <div className={template5.TextContainer}>
          {Json &&
            <>
              {documentToReactComponents(Json.json)}
            </>
          }
        </div>
        <MapButton
          onKeyDown={(ev) => { if (ev.keyCode === 13){setDisplayMapVideoModal({ ...DisplayMapVideoModal, MapModal: true })}}}
          onClick={() => { setDisplayMapVideoModal({ ...DisplayMapVideoModal, MapModal: true }) }}
        >
          {ButtonMAPA &&
            <RelativeImage
              fluid={ButtonMAPA.fluid}
              className="PrincipalButtonImage"
              alt={ButtonMAPA.description ? ButtonMAPA.description : ButtonMAPA.title}
            />
          }
          {ButtonMAPB &&
            <AbsoluteImage
              fluid={ButtonMAPB.fluid}
              className="PrincipalButtonImageAbsolute"
              alt={ButtonMAPB.description ? ButtonMAPB.description : ButtonMAPB.title}
            />
          }
        </MapButton>
      </div>
      {ListVideo &&

        <VideoModal
          DisableModal={() => { setDisplayMapVideoModal({ ...DisplayMapVideoModal, VideoModal: false }) }}
          isOpen={DisplayMapVideoModal.VideoModal}
        >
          <div style={{ 'cursor': 'pointer' }} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { setDisplayMapVideoModal({ ...DisplayMapVideoModal, VideoModal: false }) } }} onClick={() => { setDisplayMapVideoModal({ ...DisplayMapVideoModal, VideoModal: false }) }}>
            <Image fluid={ModalTitle.fluid} />
          </div>
          <iframe className={template5.embed_modal} title="iframe" src={ListVideo}>
          </iframe>
        </VideoModal>
      }
      {(MapList || !(MapList.trim() !== '-')) &&
        <MapModal
          DisableModal={() => { setDisplayMapVideoModal({ ...DisplayMapVideoModal, MapModal: false }) }}
          isOpen={DisplayMapVideoModal.MapModal}
        >
          <div style={{ 'cursor': 'pointer' }} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { setDisplayMapVideoModal({ ...DisplayMapVideoModal, MapModal: false }) } }} onClick={() => { setDisplayMapVideoModal({ ...DisplayMapVideoModal, MapModal: false }) }}>
            <Image fluid={ModalTitle.fluid} />
          </div>
          <iframe className={template5.embed_map} title="iframe" src={MapList}>
          </iframe>
        </MapModal>
      }
    </div>
  )
}

export default PrincipalModalChildren
