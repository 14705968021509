import React from 'react'
import styled from '@emotion/styled';
import Image from 'gatsby-image';

const BackButtonStyle = styled.button`
    width: 14%;
    background: unset;
    outline: none !important;
    border: 0;
    margin: 0 auto;
    left: -3%;
    top: -15%;
    position: absolute;
    &:hover .PrincipalButtonImage{
        opacity:0
    }
    &:hover .PrincipalButtonImageAbsolute{
        opacity:1;
    }
    @media only screen and (max-width: 997px){
        position: relative;
        left: 10px;
        width: 26%;
    }
    @media only screen and (max-width: 530px){
        width: 135px;
    }
`

const AbsoluteImage = styled(Image)`
    width: 100%;
    position: absolute !important;
    z-index: 50;
    top: 0;
    left: 0;
    opacity:0;
    transition: opacity 0.5s;

`
const RelativeImage = styled(Image)`
    opacity:1;
    transition: opacity 0.5s;
`

const BackButton = ({DisplayModal,BackButtonA,BackButtonB}) => {

    return (
        <BackButtonStyle onClick={DisplayModal}>
            {BackButtonA &&
            <RelativeImage 
                fluid={BackButtonA.fluid} 
                className="PrincipalButtonImage"
                alt={BackButtonA.description ? BackButtonA.description : BackButtonA.title}
            />
            }
            {BackButtonB &&
            <AbsoluteImage 
                fluid={BackButtonB.fluid} 
                className="PrincipalButtonImageAbsolute"
                alt={BackButtonB.description ? BackButtonB.description : BackButtonB.title}
            />
            }
        </BackButtonStyle>
    )
}

export default BackButton
